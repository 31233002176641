import TablePage from '@/found/components/table_page';
import { VXETable } from 'vxe-table';
import request from '@/found/utils/request';
import _ from 'xe-utils';
// import Form from '../form';

export default {
  extends: TablePage,
  components: {
    // Form,
  },
  data() {
    return {};
  },

  created() {
    this.getConfigList('terminal_statistics_list');
  },
  methods: {

    modalClick({ val, row }) {
      if (val.code === 'view') {
        this.formConfig = { ...val, code: val.code, row };
        this.modalConfig = {
          title: '查看',
          // showFooter: true,
        };
        this.openFull();
      }
      return true;
    },
  },
};
